<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="handleSubmit">
      <b-row class="d-flex align-items-center">
        <b-col cols="12" lg="3">
          <ValidationProvider v-slot="{ errors }">
            <b-form-group label="Tax Charge">
              <input
                v-model="form.tax_charge"
                type="number"
                :min="0"
                :max="100"
                class="form-control font-small-3"
              />
            </b-form-group>
            <span
              v-for="(error, index) in errors"
              :key="index"
              class="text-danger"
            >
              <small>{{ error }}</small>
            </span>
          </ValidationProvider>
        </b-col>

        <b-col class="pt-1">
          <b-button type="submit" variant="primary">
            <span v-if="!isLoading">Update Tax Charge</span>
            <b-spinner v-else />
          </b-button>
        </b-col>
      </b-row>
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { success } from "@/common/SwalOptions";

export default {
  name: "EditTaxCharge",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    data: {
      required: true,
      type: [Object],
    },
  },
  data() {
    return {
      isLoading: false,
      form: {
        tax_charge: this.data.tax_charge,
        area_name: this.data.area_name,
      },
    };
  },
  methods: {
    async handleSubmit() {
      this.isLoading = true;
      const response = await this.$http.post(
        `update_tax_charge/${this.data.id}`,
        this.form
      );
      if (response.data.status === false) {
        this.$swal({
          title: "Error !",
          icon: "warning",
          text: response.data.message,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        this.isLoading = false;
        return;
      }

      this.$swal(
        success({
          text: response.data.message,
        })
      );
      this.isLoading = false;
    },

    // onPercentChange(event) {
    //   if (event.target.value > 100) {
    //     this.form.tax_charge = 100;
    //   }
    //   if (event.target.value <= 0) {
    //     this.form.tax_charge = 0;
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped></style>
