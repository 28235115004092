<template>
  <b-overlay :show="isLoading">
    <ValidationObserver ref="form">
      <form @submit.prevent="handleSubmit">
        <b-row>
          <b-col cols="12" lg="6">
            <ValidationProvider
              v-slot="{ errors }"
              vid="postcode_range_from"
              name="Start Postcode"
              rules="required"
            >
              <b-form-group label="Start Postcode">
                <b-form-input v-model="form.postcode_range_from" />
                <span
                  v-for="(error, index) in errors"
                  :key="index"
                  class="text-danger"
                >
                  <small>{{ error }}</small>
                </span>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col cols="12" lg="6">
            <ValidationProvider
              v-slot="{ errors }"
              vid="postcode_range_to"
              name="End Postcode"
              rules="required"
            >
              <b-form-group label="End Postcode">
                <b-form-input v-model="form.postcode_range_to" />
                <span
                  v-for="(error, index) in errors"
                  :key="index"
                  class="text-danger"
                >
                  <small>{{ error }}</small>
                </span>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" lg="6">
            <ValidationProvider
              v-slot="{ errors }"
              vid="unit_price"
              name="Unit Price"
              rules="required"
            >
              <b-form-group label="Unit Price">
                <b-form-input
                  v-model="form.unit_price"
                  type="number"
                  :min="1"
                />
                <span
                  v-for="(error, index) in errors"
                  :key="index"
                  class="text-danger"
                >
                  <small>{{ error }}</small>
                </span>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col cols="12" lg="6">
            <ValidationProvider
              v-slot="{ errors }"
              vid="double_charge"
              name="Double Charge"
              rules="required"
            >
              <b-form-group label="Double Charge">
                <b-form-input v-model="form.double_charge" />
                <span
                  v-for="(error, index) in errors"
                  :key="index"
                  class="text-danger"
                >
                  <small>{{ error }}</small>
                </span>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>

        <b-row class="my-2">
          <b-col>
            <b-button type="submit" variant="primary"> Submit </b-button>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </b-overlay>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import vSelect from "vue-select";
import { required } from "@validations";

export default {
  name: "EditPrice",
  components: {
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },

  props: {
    data: {
      required: true,
      type: [Object],
    },
  },

  data() {
    return {
      isLoading: false,
      states: [],
      form: {
        id: this.data.id,
        postcode_range_from: this.data.postcode_range_from,
        postcode_range_to: this.data.postcode_range_to,
        unit_price: this.data.unit_price,
        double_charge: this.data.double_charge,
        area_id: this.data.price_checker_id,
      },
      required,
    };
  },

  created() {
    this.getStates();
  },

  methods: {
    async getStates() {
      this.isLoading = true;
      const response = await this.$http.get("state");
      if (response.data.status) {
        this.states = response.data.data.map((item) => ({
          ...item,
          label: item.state_name,
        }));
      }
      this.isLoading = false;
    },

    handleSubmit() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          this.$http
            .post(`create_or_update_sub_price/${this.data.id}`, this.form)
            .then((response) => {
              if (response.data.status) {
                this.$swal({
                  title: "Success !",
                  icon: "success",
                  text: response.data.message,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.$emit("updated");
                  }
                });
              } else {
                this.$refs.form.setErrors(response.data.errors);
              }
              this.isLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
</style>
