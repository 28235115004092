var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('ValidationProvider',{attrs:{"vid":"postcode_range_from","name":"Start Postcode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Start Postcode"}},[_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.form.postcode_range_from),callback:function ($$v) {_vm.$set(_vm.form, "postcode_range_from", $$v)},expression:"form.postcode_range_from"}}),_vm._l((errors),function(error,index){return _c('span',{key:index,staticClass:"text-danger"},[_c('small',[_vm._v(_vm._s(error))])])})],2)]}}])})],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('ValidationProvider',{attrs:{"vid":"postcode_range_to","name":"End Postcode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"End Postcode"}},[_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.form.postcode_range_to),callback:function ($$v) {_vm.$set(_vm.form, "postcode_range_to", $$v)},expression:"form.postcode_range_to"}}),_vm._l((errors),function(error,index){return _c('span',{key:index,staticClass:"text-danger"},[_c('small',[_vm._v(_vm._s(error))])])})],2)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('ValidationProvider',{attrs:{"vid":"unit_price","name":"Unit Price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Unit Price"}},[_c('b-form-input',{attrs:{"type":"number","min":1},model:{value:(_vm.form.unit_price),callback:function ($$v) {_vm.$set(_vm.form, "unit_price", $$v)},expression:"form.unit_price"}}),_vm._l((errors),function(error,index){return _c('span',{key:index,staticClass:"text-danger"},[_c('small',[_vm._v(_vm._s(error))])])})],2)]}}])})],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('ValidationProvider',{attrs:{"vid":"double_charge","name":"Double Charge","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Double Charge"}},[_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.form.double_charge),callback:function ($$v) {_vm.$set(_vm.form, "double_charge", $$v)},expression:"form.double_charge"}}),_vm._l((errors),function(error,index){return _c('span',{key:index,staticClass:"text-danger"},[_c('small',[_vm._v(_vm._s(error))])])})],2)]}}])})],1)],1),_c('b-row',{staticClass:"my-2"},[_c('b-col',[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Submit ")])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }